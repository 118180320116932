import { format } from 'date-fns';
import dataStr from 'fxdom/es/dataStr.js';
import { filter, find, go, html, map, sel, strMap } from 'fxjs/es';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { OMPMyPageOrderS } from '../../../MyPage/Order/S/Function/module/OMPMyPageOrderS.js';

export const cartProductNumberColumnTmpl = (up_c) => {
  return html`
    <span class="omp-cart__product-number">${format(new Date(up_c.created_at), 'yyyy-MM-dd')}</span>
    <span class="omp-cart__product-number">${TT('cart::cart_09', { no: up_c.id })}</span>
  `;
};

export const cartProductInfoColumnTmpl = (up_c) => {
  const thumbnail = {
    product_face: sel('_.product_color.product_faces2.value.0', up_c),
    thumbnail_url: go(sel('_.product_color.thumbnails.value', up_c), find(sel('is_thumb')), sel('url')),
  };
  const color = up_c._.product_color._.base_product_color['name' + G._en];
  const name = `${up_c._.product_color._.base_product['name' + G._en]}${color ? ` [${color}]` : ''}`;
  const print_face =
    go(
      up_c,
      sel('_.product_color.product_faces2.value'),
      filter(sel('designs.length')),
      map((pf) => pf['face_name' + G._en] || pf.face_name_en),
      (r) => r.join(' / '),
    ) || TT('cart::cart_10');

  return html`
    <div
      class="omp-cart__product-thumbnail"
      data-fx-json="${!thumbnail.thumbnail_url && thumbnail.product_face
        ? dataStr(thumbnail.product_face)
        : ''}"
    >
      <div>
        ${thumbnail.thumbnail_url
          ? html` <img src="${thumbnail.thumbnail_url}" alt="" /> `
          : html` <div class="omp-cart__product-thumbnail-canvas-wrapper">
              <canvas></canvas>
              <div class="don_loader_wrap2">
                <div class="don_loader_img2"></div>
              </div>
            </div>`}
      </div>
    </div>
    <div class="omp-cart__product-info-wrapper">
      <div class="omp-cart__product-info">
        <span class="omp-cart__product-name">${name}</span>
        <span class="omp-cart__product-print">
          ${TT('my_page::order::detail::detail_12', { print_face })}
        </span>
      </div>
    </div>
  `;
};

export const cartProductSizesColumnTmpl = (up_c) => {
  const select_option_name = up_c._.product_color._.selected_option_group['title' + G._en];
  const sizes = map(
    (up_c_s) => {
      return {
        _is_not_stock: up_c_s._is_not_stock,
        _is_discontinued: up_c_s._is_discontinued,
        size: select_option_name || up_c_s['_name' + G._en],
        quantity: UtilS.commify(up_c_s.quantity),
      };
    },
    filter((up_c_s) => up_c_s.quantity, up_c._.up_c_ss),
  );

  return strMap(
    ({ size, quantity, _is_not_stock, _is_discontinued }) => html`
      <div
        class="omp-cart__product-size-set"
        data-is_not_stock="${_is_not_stock}"
        data-is_discontinued="${_is_discontinued}"
      >
        <span class="omp-cart__product-size"
          >${size + (_is_discontinued ? ` (${TT('cart::cart_24')})` : '')}</span
        >
        <span class="omp-cart__product-quantity">${_is_discontinued ? 0 : quantity}</span>
      </div>
    `,
    sizes,
  );
};

export const cartProductPriceColumnTmpl = (up_c) => {
  const { price, discounted_price } = OMPMyPageOrderS.makeUpcProductPrice(up_c);

  return price === discounted_price
    ? html`<div>${TT('common::currency', { price })}</div>`
    : html`
        <div class="discounted">${TT('common::currency', { price })}</div>
        <div>${TT('common::currency', { price: discounted_price })}</div>
      `;
};

export const cartProductEditColumnTmpl = () => {
  return html`
    <button type="button" class="omp-cart__product-edit">${TT('cart::cart_20')}</button>
    <button type="button" class="omp-cart__product-save">${TT('cart::cart_21')}</button>
    <button type="button" class="omp-cart__product-delete">${TT('cart::cart_22')}</button>
  `;
};
