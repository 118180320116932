import { html } from 'fxjs/es';
import { OMPCoreAtomTmplS } from '../../../Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';

/**
 * Create a new custom card component with the specified options.
 * @param {string} [option.id] - The ID of the product
 * @param {string} options.thumbnail_url - The URL of the product thumbnail.
 * @param {Array} [options.header_options=[]] - The header options for the product (optional).
 * @param {string} [options.brand_name=''] - The name of the product brand (optional, default: '').
 * @param {string} [options.product_name=''] - The name of the product (optional, default: '').
 * @param {string} [options.product_description=''] - The description of the product (optional, default: '').
 * @param {string} [options.price_text=''] - The price text of the product (optional, default: '').
 * @param {string} [options.minimum_price_text=''] - The minimum price text of the product (optional, default: '').
 * @returns {string} The HTML markup for the product component.
 */

export const imageUpload = ({ src, bpid, disabled }) => {
  return src
    ? html`
        <span class="thumb-img">
          <input type="hidden" name="thumbnail_url" value="${src}" />
          <input type="hidden" name="product_id" value="${bpid}" />
          <img src="${src}" />
          <button class="thumb-img__remove" data-id="${bpid}">${OMPCoreAtomTmplS.xIcon()}</button>
        </span>
      `
    : html`<label class="file-input">
        <input
          type="file"
          ${disabled ? 'disabled' : ''}
          class="file-selector"
          accept="image/jpg, image/png, image/gif, image/webp"
        />
        <span>Select File</span>
      </label>`;
};

export const newCustomItemCard = ({
  id,
  bpid,
  one_line_description = '',
  original_price_text = '',
  minimum_price_text = '',
  name,
  model_thumb_url,
  thumbnail_url,
  isDF = false,
  is_discount,
}) => {
  const tt_custom = TT('product::new_best::new_best_1_1');
  return html`
    <div class="omp-custom-card" data-bpid="${bpid}" data-id="${id}">
      ${isDF ? html`<button class="remove-btn" data-bpid="${bpid}">${OMPCoreAtomTmplS.xIcon()}</button>` : ''}
      ${isDF
        ? html`<div class="omp-custom-card__thumbnail">
            ${imageUpload({ src: model_thumb_url, bpid, disabled: true })}
          </div>`
        : html`<div class="omp-custom-card__thumbnail">
            <a href="/${TT.lang}/product/detail?bp_id=${id}">
              <img src="${thumbnail_url || model_thumb_url}" alt="${name}" />
            </a>
          </div>`}
      <div class="omp-custom-card__meta">
        <h4 class="omp-custom-card__prod-name">${name}</h4>
        ${one_line_description ? `<p class="omp-custom-card__prod-desc">${one_line_description}</p>` : ''}
        <div class="omp-custom-card__prices">
          ${is_discount && original_price_text
            ? `<p class="omp-custom-card__prices-price">${original_price_text}</p>`
            : ''}
          <p class="omp-custom-card__prices-minimum">${minimum_price_text}</p>
        </div>
        ${!isDF
          ? html`<a href="/${TT.lang}/product/detail?bp_id=${id}" class="omp-best-card__anchor"
              >${tt_custom}</a
            >`
          : ''}
      </div>
    </div>
  `;
};

/**
 * Create a new custom card component with the specified options.
 * @param {string} [option.id] - The ID of the product
 * @param {string} options.model_thumb_url - The URL of the product thumbnail.
 * @param {Array} [options.header_options=[]] - The header options for the product (optional).
 * @param {string} [options.brand_name=''] - The name of the product brand (optional, default: '').
 * @param {string} [options.name=''] - The name of the product (optional, default: '').
 * @param {string} [options.product_description=''] - The description of the product (optional, default: '').
 * @param {string} [options.price_text=''] - The price text of the product (optional, default: '').
 * @param {string} [options.minimum_price_text=''] - The minimum price text of the product (optional, default: '').
 * @param {string} [options.review_count_text=''] - The review count text of the product (optional, default: '').
 * @returns {string} The HTML markup for the product component.
 */
export const bestItemCard = ({
  id,
  one_line_description = '',
  original_price_text = '',
  minimum_price_text = '',
  review_count = '',
  name,
  model_thumb_url,
  is_discount,
}) => {
  const tt_custom = TT('product::new_best::new_best_1_1');
  const tt_review_count = TT('common::review_count', {
    count: review_count > 999 ? '999+' : review_count,
  });
  return html`
    <div class="omp-best-card">
      <div class="omp-best-card__thumbnail">
        <a href="/${TT.lang}/product/detail?bp_id=${id}">
          <img src="${model_thumb_url}" alt="${name}" />
        </a>
        ${review_count ? `<span class="omp-best-card__review-count">${tt_review_count}</span>` : ''}
      </div>
      <div class="omp-best-card__meta">
        <h4 class="omp-best-card__prod-name">${name}</h4>
        ${one_line_description ? `<p class="omp-best-card__prod-desc">${one_line_description}</p>` : ''}
        <div class="omp-best-card__prices">
          ${is_discount && original_price_text
            ? `<p class="omp-best-card__prices-price">${original_price_text}</p>`
            : ''}
          <p class="omp-best-card__prices-minimum">${minimum_price_text}</p>
        </div>
        <a href="/${TT.lang}/product/detail?bp_id=${id}" class="omp-best-card__anchor">${tt_custom}</a>
      </div>
    </div>
  `;
};
