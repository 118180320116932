import { UtilNumberS } from '../../Number/S/Function/module/UtilNumberS.js';

export const isNbox = (plus_crew) => {
  if (!plus_crew) return false;
  return plus_crew.domain_name.toLowerCase() === 'nbox';
};

const ceilTo = (price, lang) => {
  if (lang == 'kr') {
    return Math.ceil(price / 10) * 10;
  } else if (lang == 'en') {
    return Math.ceil(price * 100) / 100;
  } else {
    return Math.ceil(price);
  }
};

export const calcPriceByExtraPercent = ({ price, extra_percent, lang }) => {
  if (parseFloat(extra_percent)) {
    return ceilTo(UtilNumberS.divDecimal(price, 1 - parseFloat(extra_percent)), lang);
  } else {
    return price;
  }
};
