import { $closest, $delegate, $find, $is, $on, $qs, $qsa } from 'fxdom/es';
import { go, each } from 'fxjs/es';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';

const component = 'mshop-comp-dropdown';

const getSelectedOptionEl = (dropdown_el) =>
  $find(`.${component}__option[data-selected="true"]`, dropdown_el);

export const unselectDropdown = (dropdown_el) => {
  const selected_option_el = getSelectedOptionEl(dropdown_el);
  if (!selected_option_el) return;

  dropdown_el.dataset.selected = 'false';
  const button_el = $find(`.${component}__button-text`, dropdown_el);
  button_el.innerText = dropdown_el.dataset.title;

  selected_option_el.dataset.selected = 'false';
};

/**
 *
 * @param {HTMLElement=} target_el
 * @param {function(Event & { delegateTarget: Element }, option): void=} onChange
 * @return void
 */
export const initDropdown = function initDropdown({ target_el, hooks: { onChange } = {} }) {
  const component = 'mshop-comp-dropdown';

  const dropdown_container_el = target_el || $qs(`.${component}`);

  if (!dropdown_container_el || dropdown_container_el.initialize) return;

  const open_input_el = $find(`.${component}__button input`, dropdown_container_el);

  const is_safari = UtilF.is_safari();

  go(
    dropdown_container_el,
    $delegate('change', `.${component}__button input`, function toggleDropdown(e) {
      dropdown_container_el.dataset.open = e.target.checked;

      // 열릴 때 다른 드랍다운들 다 닫기 - safari 만 focusout 이 제대로 동작 안해서 수동으로 꺼야 함
      if (e.target.checked == true && is_safari) {
        go(
          $qsa(`.${component}`),
          each(function closeOtherDropdowns(el) {
            if (el == dropdown_container_el) return;
            console.log('close el', el);
            closeDropdown(el);
          }),
        );
      }
    }),
    $delegate('click', `.${component}__option--group`, function toggleSubOptionGroup(e) {
      const option_group_el = e.currentTarget;
      e.originalEvent.preventDefault();

      const { idx } = option_group_el.dataset;

      const is_open = !(option_group_el.dataset.open === 'true');

      const sub_container = $find(
        `.${component}__option-sub-container[data-idx="${idx}"]`,
        dropdown_container_el,
      );

      option_group_el.dataset.open = is_open;
      sub_container.dataset.open = is_open;
    }),
    $on('focusout', function closeDropdown(e) {
      if (is_safari) return;

      const close_component_el = e.relatedTarget ? $closest(`.${component}`, e.relatedTarget) : null;
      if (close_component_el == dropdown_container_el) return;

      open_input_el.checked = false;
      dropdown_container_el.dataset.open = false;
    }),
    $delegate('click', `.${component}__option`, function selectOption(e) {
      if ($is(`.${component}__option--group`, e.currentTarget)) return;
      const { value, name, selected_name } = e.currentTarget.dataset;

      // change selected option
      const selected_option_el = getSelectedOptionEl(dropdown_container_el);
      // selected 가 없을 수도 있음
      if (selected_option_el) {
        selected_option_el.dataset.selected = 'false';
      } else {
        dropdown_container_el.dataset.selected = 'true';
      }
      e.currentTarget.dataset.selected = 'true';

      const button_text_el = $find(`.${component}__button-text`, dropdown_container_el);
      button_text_el.innerText = selected_name || name;

      open_input_el.checked = !open_input_el.checked;
      dropdown_container_el.dataset.open = open_input_el.checked;

      onChange(e, { value, name });
    }),
  );

  dropdown_container_el.initialize = true;
};

export const closeDropdown = (dropdown_el) => {
  dropdown_el.dataset.open = 'false';
  const status_input_el = $find(`.${component}__button input`, dropdown_el);
  status_input_el.checked = false;
};

export const findDropdownEl = function findDropdownEl(container_el) {
  return $find(`.${component}`, container_el);
};
