import { html, omitBy } from 'fxjs/es';
import querystring from 'querystring';
import { UtilS } from '../../../S/Function/module/UtilS.js';

const RESIZE_IMAGE_URL = 'image1.marpple.co';
const ORIGIN_IMAGE_URL = 's3.marpple.co';

const is_gif = (url) => /.+\.gif$/.test(url);

const addProtocol = (url) => ([/^http/, /^https/].some((re) => re.test(url)) ? url : 'https:' + url);

export const getResizedUrl = ({ url, quality, height, width, format, bg, r }) => {
  if (!url || is_gif(url)) return url || '';
  const url_obj = new URL(addProtocol(url));
  const params = { q: quality, h: height, w: width, f: format, bg, r };

  const qs = UtilS.makeQueryString(params) || '';

  return UtilS.escape(`https://${RESIZE_IMAGE_URL}${url_obj.pathname}?${qs}`);
};

export const updateResizeOption = ({ url, quality, height, width, format, bg, is_ie }) => {
  if (!url || is_gif(url)) return url;
  const url_obj = new URL(addProtocol(url));
  const prev_option = querystring.parse(url_obj.search.slice(1));
  const f = is_ie && format == 'webp' ? '' : format;
  const params = { ...prev_option, ...omitBy(([k, v]) => !v, { q: quality, h: height, w: width, f, bg }) };
  const qs = UtilS.makeQueryString(params) || '';

  return UtilS.escape(`https://${RESIZE_IMAGE_URL}${url_obj.pathname}?${qs}`);
};

export const getResized = ({ klass, ...resize_option }) => {
  return !resize_option.url.includes(ORIGIN_IMAGE_URL)
    ? html`<img src="${UtilS.escape(resize_option.url)}" class="${klass}" />`
    : html`<img src="${getResizedUrl(resize_option)}" class="${klass}" />`;
};

export const getResized70Webp = (url, width) => {
  return getResizedUrl({
    url,
    width: width * 2,
    quality: 70,
    format: 'webp',
  });
};

export const getResized70WebpImages = (urls, width) => {
  return urls.map((url) =>
    getResizedUrl({
      url,
      width: width * 2,
      quality: 70,
      format: 'webp',
    }),
  );
};
