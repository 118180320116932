import { format } from 'date-fns';
import dataStr from 'fxdom/es/dataStr.js';
import { filter, find, go, html, map, sel, strMap } from 'fxjs/es';
import { zipWithIndex } from 'fxjs/es/Lazy';
import { PriceS } from '../../../../Price/S/Function/module/PriceS.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { OMPCoreAtomTmplS } from '../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
import { OMPCoreUtilS } from '../../../Core/Util/S/Function/module/OMPCoreUtilS.js';
import { OMPMyPageOrderS } from '../../../MyPage/Order/S/Function/module/OMPMyPageOrderS.js';
import { OMPCartS } from '../Function/module/OMPCartS.js';
import { separator } from './cartTmplPc.js';

export const cartUserProductColor = (up_c, is_last) => {
  const thumbnail = {
    product_face: sel('_.product_color.product_faces2.value.0', up_c),
    thumbnail_url: go(sel('_.product_color.thumbnails.value', up_c), find(sel('is_thumb')), sel('url')),
  };
  const color = up_c._.product_color._.base_product_color['name' + G._en];
  const name = `${up_c._.product_color._.base_product['name' + G._en]} `;
  const print_face =
    go(
      up_c,
      sel('_.product_color.product_faces2.value'),
      filter(sel('designs.length')),
      map((pf) => pf['face_name' + G._en] || pf.face_name_en),
      (r) => r.join(' / '),
    ) || TT('cart::cart_10');

  const select_option_name = up_c._.product_color._.selected_option_group['title' + G._en];

  const sizes = map(
    (up_c_s) => ({
      _is_not_stock: up_c_s._is_not_stock,
      _is_discontinued: up_c_s._is_discontinued,
      size: select_option_name || up_c_s['_name' + G._en],
      quantity: UtilS.commify(up_c_s.quantity),
    }),
    filter((up_c_s) => up_c_s.quantity, up_c._.up_c_ss),
  );
  const { price, discounted_price } = OMPMyPageOrderS.makeUpcProductPrice(up_c);

  const product_detail_url = OMPMyPageOrderS.makeUpcUrl(up_c);

  return html`
    <div
      class="omp-cart__product ${is_last ? 'is_last' : ''}"
      ${OMPCoreUtilS.createDataSet(up_c?.table_data)}
    >
      <a href="${product_detail_url}" class="omp-cart__product-id">
        <span class="omp-cart__product-number">${format(new Date(up_c.created_at), 'yyyy-MM-dd')}</span>
        <span class="omp-cart__product-number">${TT('cart::cart_09', { no: up_c.id })}</span>
      </a>
      <a href="${product_detail_url}" class="omp-cart__product-info-wrapper">
        <div
          class="omp-cart__product-thumbnail"
          data-fx-json="${!thumbnail.thumbnail_url && thumbnail.product_face
            ? dataStr(thumbnail.product_face)
            : ''}"
        >
          <div>
            ${thumbnail.thumbnail_url
              ? html` <img src="${thumbnail.thumbnail_url}" alt="" /> `
              : html` <div class="omp-cart__product-thumbnail-canvas-wrapper">
                  <canvas></canvas>
                  <div class="don_loader_wrap2">
                    <div class="don_loader_img2"></div>
                  </div>
                </div>`}
          </div>
        </div>
        <div class="omp-cart__product-info">
          <div class="omp-cart__product-name">${name}</div>
          <div class="omp-cart__product-print">
            <span class="key">${TT('my_page::order::detail::detail_12_01')}</span>
            <span>${print_face}</span>
          </div>
          ${color
            ? html`
                <div class="omp-cart__product-print">
                  <span class="key">${TT('my_page::order::detail::detail_08')}</span>
                  <span>${color}</span>
                </div>
              `
            : ''}
          ${sizes.length
            ? html`<div class="omp-cart__product-size">
                <span class="key">${TT('my_page::order::detail::detail_06')}</span>
                <div class="omp-cart__product-size-set-wrapper">
                  ${strMap(
                    ({ size, quantity, _is_not_stock, _is_discontinued }) => html`
                      <div
                        class="omp-cart__product-size-set"
                        data-is_not_stock="${_is_not_stock}"
                        data-is_discontinued="${_is_discontinued}"
                      >
                        <span>${size + (_is_discontinued ? ` (${TT('cart::cart_24')})` : '')}</span>
                        <span>${TT('common::quantity', { quantity: _is_discontinued ? 0 : quantity })}</span>
                        <input name="quantity" type="hidden" value="${_is_discontinued ? 0 : quantity}" />
                      </div>
                    `,
                    sizes,
                  )}
                </div>
              </div>`
            : ''}
        </div>
      </a>
      <div class="omp-cart__product-price">
        ${price === discounted_price
          ? html` <div>${TT('common::currency', { price })}</div> `
          : html`
              <div class="discounted">${TT('common::currency', { price })}</div>
              <div>${TT('common::currency', { price: discounted_price })}</div>
            `}
      </div>
      <div class="omp-cart__product-edit-wrapper">
        <div class="omp-cart__product-edit-set">
          <button type="button" class="omp-cart__product-edit">${TT('cart::cart_20')}</button>
          <button type="button" class="omp-cart__product-save">${TT('cart::cart_21')}</button>
        </div>
        <button type="button" class="omp-cart__product-delete">${TT('cart::cart_22')}</button>
      </div>
    </div>
  `;
};

const cartUserProduct = (up_cs) =>
  html`
    <div class="omp-cart__product-container">
      ${strMap(
        ([index, up_c]) => cartUserProductColor(up_c, index + 1 === up_cs.length),
        zipWithIndex(up_cs),
      )}
      ${separator(up_cs)}
    </div>
  `;

export const cartTmplMo = ({ user_products, price_info, shipping_price }) => {
  const total_quantity = TT('cart::cart_12');
  const total_product_price = TT('cart::cart_13');
  const total_shipping = TT('cart::cart_14');
  const total_price = TT('cart::cart_15');
  const empty_notice = TT('cart::cart_18');
  const empty_desc = TT('cart::cart_19');

  const up_cs = OMPCartS.processUserProductsForCartTable(user_products);

  const is_free_shipping_price_kr = 100000 - price_info.product_price;

  return html`
    <div class="omp-cart">
      ${!user_products.length
        ? html`
            <div class="omp-cart__empty">
              <div class="omp-cart__empty-notice">${empty_notice}</div>
              <div class="omp-cart__empty-desc">${empty_desc}</div>
            </div>
          `
        : html`
            <div class="omp-cart__product-wrapper">${strMap(cartUserProduct, up_cs)}</div>
            <div class="omp-cart__summary">
              <div class="omp-cart__summary-body">
                <div class="omp-cart__summary-value">
                  <span class="omp-cart__summary-value-title">${total_quantity}</span>
                  <span class="omp-cart__summary-body-value">
                    ${TT('common::quantity', { quantity: UtilS.commify(price_info.quantity) })}
                  </span>
                </div>
                <div class="omp-cart__summary-value">
                  <span class="omp-cart__summary-value-title">${total_product_price}</span>
                  <span class="omp-cart__summary-body-value">
                    ${TT('common::currency', { price: PriceS.pricify(price_info.product_price) })}
                  </span>
                </div>
                <div class="omp-cart__summary-value">
                  <span class="omp-cart__summary-value-title">${total_shipping}</span>
                  <span class="omp-cart__summary-body-value">
                    ${TT('common::currency', { price: PriceS.pricify(shipping_price) })}
                  </span>
                </div>
                <div class="omp-cart__summary-value total">
                  <span class="omp-cart__summary-value-title">${total_price}</span>
                  <span class="omp-cart__summary-body-value" data-style_total="true">
                    ${TT('common::currency', {
                      price: PriceS.pricify(PriceS.add(price_info.product_price, shipping_price)),
                    })}
                  </span>
                </div>
                ${TT.lang === 'kr'
                  ? html` <div class="omp-cart__shipping-price-notice-wrapper">
                      <div class="omp-cart__shipping-price-notice">
                        <span class="omp-cart__shipping-price-notice-desc">
                          ${is_free_shipping_price_kr <= 0
                            ? '무료로 배송해 드려요!'
                            : html` ${UtilS.commify(is_free_shipping_price_kr)}원 더 담으면
                                <span class="og">배송비 무료</span>`}
                        </span>
                        ${is_free_shipping_price_kr <= 0
                          ? ''
                          : html` <a href="/${TT.lang}/best-products">
                              <div class="omp-cart__shipping-price-notice-more">
                                ${OMPCoreAtomTmplS.plusIcon()}
                              </div>
                              <span> 더 담으러 가기 </span>
                            </a>`}
                      </div>
                    </div>`
                  : ''}
              </div>
              <div class="omp-cart__button">
                ${OMPCoreAtomTmplS.actionButton({
                  klass: 'omp-cart__checkout',
                  text: TT('cart::cart_16'),
                })}
              </div>
            </div>
          `}
    </div>
  `;
};
