export const colors = {
  ms_blue: '#0E2EE2',
  ss_sky: '#3C89F9',
  white: ' #FFFFFF',
  black: ' #111111',
  gray_100: '#F8F8F8',
  gray_200: '#F2F2F2',
  gray_300: '#DDDDDD',
  gray_400: '#CCCCCC',
  gray_500: '#A2A2A2',
  gray_600: '#828282',
  gray_700: '#4E4E4E',
  gray_800: '#111111',
  dim_25: 'rgba(17, 17, 17, 0.25)',
  dim_50: 'rgba(17, 17, 17, 0.5)',
  dim_80: 'rgba(17, 17, 17, 0.80)',
  dim_90: 'rgba(17, 17, 17, 0.90)',
  error: '#F66565',
  green_100: '#E5F8F1',
  green_200: '#CCF2E4',
  green_300: '#9AE4C9',
  green_400: '#80DDBB',
  green_500: '#67D7AD',
  green_600: '#54D2A3',
  green_700: '#35C992',
  green_800: '#02BC77',
  magenta_100: '#FEF3FA',
  magenta_200: '#FDE8F5',
  magenta_300: '#FBC5E8',
  magenta_400: '#F8A3DB',
  magenta_500: '#F68CD1',
  magenta_600: '#F46EC5',
  magenta_700: '#F147B6',
  magenta_800: '#EE19A4',
  mint_100: '#F4FDFB',
  mint_200: '#E8FBF7',
  mint_300: '#C8F5EC',
  mint_400: '#A7EFE1',
  mint_500: '#90EBD9',
  mint_600: '#73E5CE',
  mint_700: '#4EE0C2',
  mint_800: '#22D8B3',
  orange_100: '#FFF8F2',
  orange_200: '#FFF0E5',
  orange_300: '#FFDCBF',
  orange_400: '#FFC799',
  orange_500: '#FFB87F',
  orange_600: '#FFA55D',
  orange_700: '#FF8E33',
  orange_800: '#FF7200',
  purple_100: '#F6F3FD',
  purple_200: '#EEE6FC',
  purple_300: '#D5C2F8',
  purple_400: '#BC9EF3',
  purple_500: '#AB86F0',
  purple_600: '#9566EC',
  purple_700: '#793DE8',
  purple_800: '#580DE2',
  red_100: '#FEF3F4',
  red_200: '#FDE7EA',
  red_300: '#FAC3CB',
  red_400: '#F7A0AD',
  red_500: '#F58898',
  red_600: '#F2687C',
  red_700: '#F0415A',
  red_800: '#EC1131',
  sky_100: '#F5F9FE',
  sky_200: '#EBF3FE',
  sky_300: '#CEE1FD',
  sky_400: '#B1D0FD',
  sky_500: '#9DC4FC',
  sky_600: '#83B4FA',
  sky_700: '#63A1FA',
  sky_800: '#3C89F9',
  yellow_100: '#FFFDF2',
  yellow_200: '#FFFBE5',
  yellow_300: '#FFF5C0',
  yellow_400: '#FFEF9A',
  yellow_500: '#FFEB80',
  yellow_600: '#FFE55E',
  yellow_700: '#FFDE28',
  yellow_800: '#FFD802',
};
