import BLazy from 'blazy';
import {
  $addClass,
  $find,
  $height,
  $qs,
  $removeClass,
  $scrollTop,
  $setAttr,
  $setCss,
  $setScrollTop,
  $setText,
} from 'fxdom/es';
import { go, html, mapObject, object } from 'fxjs/es';
import UAParser from 'ua-parser-js';
import { UtilF } from '../../../../../Util/F/Function/module/UtilF.js';
import { OMPCoreUtilS } from '../../S/Function/module/OMPCoreUtilS.js';

const parser = new UAParser();
const is_mobile_cache = ['mobile'].includes(parser.getDevice().type);
const is_tablet_cache = ['tablet'].includes(parser.getDevice().type);

export const isWhale = () => parser.getBrowser()?.name?.toLowerCase() === 'whale';
export const isMobile = () => {
  if (UtilF.isForceM()) return true;
  return is_mobile_cache;
};
export const isTablet = () => {
  if (UtilF.isForceM()) return;
  if (!isMobile() && window.navigator.maxTouchPoints === 5) return true;
  /* ipad 구별 */
  return is_tablet_cache;
};
export const isIOS = () => /IOS/i.test(parser.getOS()?.name);
export const isAndroid = () => /Android/i.test(parser.getOS()?.name);

export const isMpApp = () =>
  !!window.ReactNativeWebView && window.navigator.userAgent.indexOf('MarppleApp') > -1;
export const getOSVersion = () => parser.getOS()?.version;

export const alert = ({ content = '', ok = '', msg_width }) => {
  const prom = $.alert(html`
    <div class="custom-body">
      <p>${content}</p>
    </div>
  `);

  setTimeout(() => {
    const el = $qs('.don_dialog_msg');
    if (el) $setAttr({ type: 'alert' }, el);
    if (ok) go(el, $find('.ok'), $setText(ok));
    if (msg_width) go(el, $find('.msg'), $setCss({ maxWidth: msg_width }));
    if (msg_width) go(el, $find('.custom-body'), $setCss({ maxWidth: msg_width }));
  }, 0);

  return prom;
};

export const titleAlert = ({ title, message, content = '', ok = '', style = { content_color: 'BK' } }) => {
  const prom = $.alert(html`
    <div class="custom-body">
      ${title ? html` <span class="omp-msg-title">${title}</span>` : ''}
      <span
        ${OMPCoreUtilS.createStyleSet(style)}
        class="omp-msg-message">${message || content}</p>
    </div>
  `);

  setTimeout(() => {
    const el = $qs('.don_dialog_msg');
    if (el) $setAttr({ type: 'alert_title' }, el);
    if (ok) go(el, $find('.ok'), $setText(ok));
  }, 0);

  return prom;
};

export const confirm = ({
  content = '',
  title,
  cancel = '',
  ok = '',
  style = { color_reverse: false, reverse: false, column: false },
}) => {
  const prom = $.confirm(html`
    <div class="custom-body">
      ${title ? html` <span class="omp-msg-title">${title}</span>` : ''}
      <p class="omp-msg-message">${content}</p>
    </div>
  `);

  setTimeout(() => {
    const el = $qs('.don_dialog_msg');
    if (el) $setAttr({ type: 'confirm' }, el);
    if (cancel) {
      go(
        el,
        $find('.cancel'),
        $setText(cancel),
        $setAttr({
          color_reverse: style.color_reverse,
        }),
      );
    }
    if (ok) {
      go(
        el,
        $find('.ok'),
        $setText(ok),
        $setAttr({
          color_reverse: style.color_reverse,
        }),
      );
    }
    if (style.reverse) {
      go(el, $find('.buttons'), $setCss({ flexDirection: 'row-reverse' }));
    }

    go(el, $find('.buttons'), (buttons_el) => {
      buttons_el.dataset.column = style.column;
    });
  }, 0);

  return prom;
};
const marpple_blazy = new BLazy({
  selector: '.omp-image-lazy',
  offset: window.innerHeight,
});
export const imageLazyLoad = (lazy) => {
  (lazy || marpple_blazy).revalidate();
};

/**
 * form_el 또는 FormData를 object로 변환
 * @param {HTMLFormElement|FormData} form
 * @return {object}
 */
export const formDataToObject = (form) => {
  let form_data;
  if (form instanceof HTMLFormElement) {
    form_data = new FormData(form);
  } else if (form instanceof FormData) {
    form_data = form;
  } else {
    throw new Error('form is not valid.');
  }

  return go(
    form_data.entries(),
    object,
    mapObject((str) => str.trim()),
  );
};

export const bodyFixed$ = (be_fix) => {
  if (be_fix) {
    if (!isMobile() && $height(document.body) >= window.innerHeight) {
      $setCss({ 'overflow-y': 'scroll' }, document.body);
    }

    document.body.fixed_top = $scrollTop(window);
    go(document.body, $addClass('body-fixed'), $setCss({ top: -document.body.fixed_top }));
  } else {
    go(document.body, $removeClass('body-fixed'), $setCss({ top: '', 'overflow-y': '' }));
    $setScrollTop(document.body.fixed_top, window);
    document.body.fixed_top = void 0;
  }
};
