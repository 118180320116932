import { go, map } from 'fxjs/es';
import { getTotalQuantity } from './cart.js';

export const processUserProductsForCartTable = (user_products) => {
  return go(
    user_products,
    map((up) => {
      return map((up_c) => {
        up_c._.product_color._.base_product._ = {
          ...up_c._.product_color._.base_product._,
          base_product_colors: up._.product_color._.base_product._.base_product_colors,
        };
        return {
          ...up_c,
          base_product_colors: up._.product_color._.base_product._.base_product_colors,
          table_data: {
            up_id: up_c.up_id,
            up_c_id: up_c.id,
            quantity: getTotalQuantity(up_c),
          },
        };
      }, up._.up_cs);
    }),
  );
};
