import { format } from 'date-fns';
import { html, identity, range, strMap } from 'fxjs/es';
import { UtilImageS } from '../../../../../Util/Image/S/Function/module/UtilImageS.js';
import { OMPCoreAtomTmplS } from '../../../Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
import { OMPCoreUtilS } from '../../../Util/S/Function/module/OMPCoreUtilS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';

/**
 * @typedef {BaseReviewParam} ReviewParam
 * @property {string} description
 * @property {Product} product
 */

/**
 * @typedef BaseReviewParam
 * @property {string} id
 * @property {string} thumbnail_url
 * @property {number} review_score
 * @property {number} acc_purchase 누적 구매 횟수
 * @property {boolean} is_hot
// is_marpple_picked은 아직 구현되지 않았습니다.
 * @property {boolean} is_marpple_picked
 * @property {string} user_id
 * @property {string} created_at
 */

/**
 * @typedef {object} Product
 * @property {number} product_id
 * @property {string} thumbnail_url
 * @property {string} name
 * @property {string} size
 * @property {category_id: number, name: string} category
 */

/**
 * @typedef {object} StarRatingParam
 * @property {number} score
 * @property {number} [max_rate=5] max_rate
 */

/**
 * @param {StarRatingParam} param
 */
export const reviewStarRatingTmpl = ({ score, max_rate = 5 } = {}) => {
  if (!Number.isInteger(score) || !Number.isInteger(max_rate) || score > max_rate) {
    throw new Error('InvalidArgs: reviewStarRatingTmpl');
  }

  return html`
    <div class="omp-cell__review-score">
      ${strMap(() => html`<span class="omp-cell__review-star"></span>`)(range(score))}
      ${strMap(() => html`<span class="omp-cell__review-star" data-off="true"></span>`)(
        range(max_rate - score),
      )}
    </div>
  `;
};

export const reviewFloatStarRatingTmpl = ({ score, max_rate = 5 } = {}) => {
  if (typeof score !== 'number' || !Number.isInteger(max_rate) || score > max_rate) {
    throw new Error('InvalidArgs: reviewFloatStarRatingTmpl');
  }
  const int_part = parseInt(score);
  const dec_part = score - int_part;

  const dec_percent = dec_part * 100;
  return html`
    <div class="omp-cell__review-score">
      ${strMap(() => html`<span class="omp-cell__review-star"></span>`)(range(int_part))}
      ${int_part !== max_rate
        ? html`
            <span
              class="omp-cell__review-star"
              style="-webkit-mask-image:linear-gradient(to right, black 0%, black ${dec_percent}%, rgba(0,0,0,0.15) ${dec_percent}%, rgba(0,0,0,0.15) 100%);"
            ></span>
            ${strMap(() => html`<span class="omp-cell__review-star" data-off="true"></span>`)(
              range(max_rate - (int_part + 1)),
            )}
          `
        : ''}
    </div>
  `;
};

export const reviewMetaTmpl = ({
  acc_purchase,
  is_hot,
  is_marpple_picked,
  user,
  created_at,
  is_horizontal,
} = {}) => {
  return html`
    <div class="omp-cell__review-meta" data-style_is_horizontal="${is_horizontal}">
      ${acc_purchase ? OMPCoreAtomTmplS.badgeSmall({ color: 'gy_50', text: acc_purchase }) : ''}
      ${is_hot && is_horizontal ? OMPCoreAtomTmplS.badgeSmall({ color: 'og', text: 'Hot' }) : ''}
      <span class="omp-cell__review__user-id">${user.email}</span>
      <span class="omp-cell__review__created-at">${format(new Date(created_at), 'yyyy.MM.dd')}</span>
    </div>
  `;
};

export const reviewProductInfoTmpl = ({
  base_product_color,
  base_product,
  size_info,
  category_more,
} = {}) => {
  const product_url = `/${TT.lang}/product/detail?bp_id=${base_product.id}`;

  return html`
    <div class="omp-cell__review__product-info">
      <a href="${product_url}" class="omp-cell__review__product-thumbnail">
        <img
          src="${UtilImageS.getResized70Webp(
            base_product_color.product_thumb_url || base_product.base_product_color_face_url,
            52,
          )}"
          alt="product-thumbnail"
        />
      </a>
      ${!category_more
        ? html`
            <a href="${product_url}" class="omp-cell__review__product-meta">
              <div class="omp-cell__review__product-name">${base_product.name}</div>
              <div class="omp-cell__review__product-size">${size_info}</div>
            </a>
          `
        : html`
            <div class="omp-cell__review__product-meta">
              <a href="${product_url}" class="omp-cell__review__product-name">${base_product.name}</a>
              <a href="${product_url}" class="omp-cell__review__product-size">${size_info}</a>
              ${base_product?._?.cate_item?.name
                ? html`<div class="omp-cell__review__product-category">
                    <a
                      href="/${TT.lang}/product/list/${base_product.cate_list_id}?cate_item_id=${base_product.cate_item_id}"
                    >
                      ${category_more}
                    </a>
                  </div>`
                : ''}
            </div>
          `}
    </div>
  `;
};

/**
 * @param {ReviewParam} param
 * @return {string}
 */
export const verticalReviewTmpl = ({
  id,
  score,
  is_best = false,
  files,
  acc_purchase,
  // is_marpple_picked은 아직 구현되지 않았습니다.
  is_marpple_picked = false,
  created_at,
  comment,
  sns_url,
  is_me,
  _: { base_product_color, base_product, up_c, user },
}) => {
  const read_more = TT('cell::review::read_more');
  const category_more = TT('cell::review::category_more', { name: base_product?._?.cate_item?.name });

  const size_info = up_c?._
    ? TT('cell::review::size_info', {
        size:
          up_c._?.product_color._?.selected_option_group?.title ||
          up_c._?.up_c_ss
            .map((up_c_s) => up_c_s._name)
            .slice(0, 2)
            .filter(identity)
            .join(' / '),
      })
    : '';

  const can_open = !!(sns_url || files.length || !OMPCoreUtilS.isMobile());
  const acc_purchase_text = acc_purchase ? TT('cell::review::acc_purchase', { count: acc_purchase }) : '';

  return html`
    <article id="${id}" class="omp-cell__review" data-type="vertical" data-can_open="${can_open}">
      ${files && files.length
        ? html`<div class="omp-cell__review-image">
            <img
              src="${UtilImageS.getResizedUrl({ url: files[0].url, quality: 70, width: 800 })}"
              alt="review-thumbnail"
            />
            <div class="omp-cell__review-badges">
              ${is_best ? OMPCoreAtomTmplS.badge({ text: 'Hot', color: 'og' }) : ''}
              ${is_marpple_picked ? OMPCoreAtomTmplS.badge({ text: '마플 Pick' }) : ''}
            </div>
          </div>`
        : ''}
      <div class="omp-cell__review-info">
        ${reviewStarRatingTmpl({ score })}
        ${reviewMetaTmpl({
          acc_purchase: acc_purchase_text,
          files,
          is_hot: is_best,
          is_marpple_picked,
          user,
          created_at,
        })}
        <div class="omp-cell__review__description-wrapper">
          <div class="omp-cell__review__description">${UtilS.escape(comment)}</div>
          <span class="omp-cell__review__read-more">${read_more}</span>
        </div>
      </div>
      ${reviewProductInfoTmpl({ base_product_color, base_product, size_info })}
      ${base_product?._?.cate_item?.name
        ? html`<div class="omp-cell__review__product-category">
            <a
              href="/${TT.lang}/product/list/${base_product.cate_list_id}?cate_item_id=${base_product.cate_item_id}"
            >
              ${category_more}
            </a>
          </div>`
        : ''}
      ${removeButtonHtml(is_me)}
    </article>
  `;
};

/**
 * @param {BaseReviewParam} param
 * @return {string}
 */
export const verticalReviewMoTmpl = ({
  id,
  score,
  is_best = false,
  files,
  acc_purchase,
  // is_marpple_picked은 아직 구현되지 않았습니다.
  is_marpple_picked = false,
  created_at,
  sns_url,
  is_me,
  _: { user },
}) => {
  const can_open = !!(sns_url || files.length || !OMPCoreUtilS.isMobile());

  return html`
    <article
      id="${id}"
      class="omp-cell__review omp-cell__review-mo"
      data-type="vertical"
      data-can_open="${can_open}"
    >
      <div class="omp-cell__review-image">
        <img
          src="${UtilImageS.getResizedUrl({ url: files[0].url, quality: 70, width: 336, format: 'webp' })}"
          alt="review-thumbnail"
        />
      </div>
      <div class="omp-cell__review-badges">
        ${is_best ? OMPCoreAtomTmplS.badge({ text: 'Hot', color: 'og' }) : ''}
        ${is_marpple_picked ? OMPCoreAtomTmplS.badge({ text: '마플 Pick' }) : ''}
      </div>
      ${reviewStarRatingTmpl({ score })}
      <div class="omp-cell__review-meta">
        <div>
          ${acc_purchase ? OMPCoreAtomTmplS.badgeSmall({ color: 'gy_50', text: acc_purchase }) : ''}
          <span class="omp-cell__review__user-id">${user.email}</span>
        </div>
        <span class="omp-cell__review__created-at">${format(new Date(created_at), 'yyyy-MM-dd')}</span>
      </div>
      ${removeButtonHtml(is_me)}
    </article>
  `;
};

/**
 * @param {ReviewParam} param
 */
export const horizontalReviewTmpl = ({
  id,
  score,
  is_best = false,
  files,
  acc_purchase,
  // is_marpple_picked은 아직 구현되지 않았습니다.
  is_marpple_picked = false,
  created_at,
  comment,
  sns_url,
  is_me,
  _: { base_product_color, base_product, up_c, user },
}) => {
  const read_more = TT('cell::review::read_more');
  const category_more = TT('cell::review::category_more', { name: base_product?._?.cate_item?.name });
  const size_info = up_c?._
    ? TT('cell::review::size_info', {
        size:
          up_c._?.product_color._?.selected_option_group?.title ||
          up_c?._?.up_c_ss
            .map((up_c_s) => up_c_s._name)
            .slice(0, 2)
            .filter(identity)
            .join(' / '),
      })
    : '';

  const can_open = !!(sns_url || files.length);

  return html`
    <article id="${id}" class="omp-cell__review" data-type="horizontal" data-can_open="${can_open}">
      <div class="omp-cell__review__body">
        <div>
          ${reviewStarRatingTmpl({ score })}
          ${reviewMetaTmpl({
            acc_purchase,
            files,
            is_hot: is_best,
            is_marpple_picked,
            user,
            created_at,
            is_horizontal: true,
          })}
        </div>
        <div class="omp-cell__review__description-wrapper">
          <div class="omp-cell__review__description">${UtilS.escape(comment)}</div>
          <span class="omp-cell__review__read-more">${read_more}</span>
        </div>
        ${reviewProductInfoTmpl({ base_product_color, base_product, size_info, category_more })}
      </div>
      ${files && files.length
        ? html`
            <div class="omp-cell__review-image">
              <img
                src="${UtilImageS.getResizedUrl({
                  url: files[0].url,
                  quality: 70,
                  width: 532,
                  format: 'webp',
                })}"
                alt="review-thumbnail"
              />
              ${files.length > 1 ? html`<i class="omp-atom__icon-multiple"></i>` : ''}
            </div>
          `
        : ''}
      ${removeButtonHtml(is_me)}
    </article>
  `;
};

const removeButtonHtml = (is_me) => {
  if (is_me) {
    return html`<div class="omp-cell__review-delete">
      ${OMPCoreAtomTmplS.closeButton({ color: 'black' })}
    </div>`;
  }
  return '';
};
