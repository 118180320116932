import axios from 'axios';
import { $attr, $delegate } from 'fxdom/es';
import $parseDataStr from 'fxdom/es/parseDataStr.js';
import { delay, go } from 'fxjs/es';
import { MShopUtilF } from '../../../../../../modules/MShop/Util/F/Function/module/MShopUtilF.js';

if (MShopUtilF.isApp()) {
  go(
    document.body,
    $delegate('click', '[data-post-message]', (e) => {
      e.originalEvent.preventDefault();
      MShopUtilF.postMessage($parseDataStr(e.currentTarget.dataset.postMessage));
    }),
    $delegate('click', 'a.mp-shop-logout', async (e) => {
      e.originalEvent.preventDefault();
      $.don_loader_start();
      go(axios.get(e.currentTarget.href), delay(600), () => MShopUtilF.postMessage({ logout: true }));
    }),
    $delegate('click', 'a.mp-shop-lang', (e) => {
      e.originalEvent.preventDefault();
      $.don_loader_start();
      MShopUtilF.postMessage({ locale: $attr('lang', e.currentTarget) });
    }),
  );
}
