export const CONFIRM_HAS_NOT_DESIGNED_FACE = 'CONFIRM_HAS_NOT_DESIGNED_FACE';
export const UNABLE_UNDO_REDO = 'UNABLE_UNDO_REDO';
export const DIFFERENT_DELIVERY_FEE = 'DIFFERENT_DELIVERY_FEE';

export const WHEN_CHANGE_REMOVE_DESIGN = 'WHEN_CHANGE_REMOVE_DESIGN';

export const NOT_SHOW_ADD_COLOR = 'NOT_SHOW_ADD_COLOR';
export const NOT_WORKING_COPY_AND_PASTE = 'NOT_WORKING_COPY_AND_PASTE';
export const LINK_TO_BP_ID = 'LINK_TO_BP_ID';

export const DOSU_1_OR_2 = 'DOSU_1_OR_2';
export const NO_REQUEST_BG_REMOVAL = 'NO_REQUEST_BG_REMOVAL';

export const COMPOSITE_BPC_COLOR_CODE2 = 'COMPOSITE_BPC_COLOR_CODE2';
