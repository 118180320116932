import { UtilS } from '../../S/Function/module/UtilS.js';
import { $qs } from 'fxdom/es';
import { go, isNil, merge, omitBy } from 'fxjs/es';
import querystring from 'query-string';

/**
 *
 * @param {Record<string, string | null>} params
 * @return {string}
 */
export const updateUrlParams = (params, preserve_prev) =>
  UtilS.updateUrlParams(location.href, params, preserve_prev);

/**
 *
 * @param {Record<string, string | null>} params
 * @return {Record<string, string | null>}
 */
export const updateAndParseUrlParams = (params) => {
  return go(
    querystring.parse(location.search),
    (prev) => merge(prev, params),
    omitBy(([, v]) => isNil(v)),
  );
};

export const isBackForward = () => {
  try {
    return window.performance.getEntriesByType('navigation')[0].type == 'back_forward';
  } catch (e) {
    return window?.performance?.navigation?.type == 2;
  }
};

export const initBackForward = async (func) => {
  if (isBackForward()) await func();
  window.addEventListener('pageshow', (e) => {
    if (e.persisted) func(true);
  });
};

/**
 *
 * @param {string} title
 * @return void
 */
export const changeMetaTitle = (title) => {
  const title_el = $qs('title');
  if (!title_el) return;

  title_el.innerText = title;
};

export const clipboard = (text) => {
  const _clipboard = (function (window, document, navigator) {
    let textArea;

    function isOS() {
      return navigator.userAgent.match(/ipad|iphone/i);
    }

    function createTextArea(text) {
      textArea = document.createElement('textArea');
      textArea.value = text;
      document.body.appendChild(textArea);
    }

    function selectText() {
      let range, selection;

      if (isOS()) {
        range = document.createRange();
        range.selectNodeContents(textArea);
        selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.setSelectionRange(0, 999999);
      } else {
        textArea.select();
      }
    }

    function copyToClipboard() {
      document.execCommand('copy');
      document.body.removeChild(textArea);
    }

    return {
      copy: (text) => {
        createTextArea(text);
        selectText();
        copyToClipboard();
      },
    };
  })(window, document, navigator);

  _clipboard.copy(text);
};
