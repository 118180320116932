import axios from 'axios';
import { $addClass, $find, $qs, $qsa, $removeClass, $setAttr, $setText } from 'fxdom/es';
import { curry2, each, go, sumBy } from 'fxjs/es';
import { MShopUtilF } from '../../../MShop/Util/F/Function/module/MShopUtilF.js';
import { calcDiscountedPrice } from '../../../ProductColorPrice/S/fs.js';
import { OMPCoreUtilF } from '../../../OMP/Core/Util/F/Function/module/OMPCoreUtilF.js';

export function resetUserCounts(user_counts) {
  if ($qs('html.marpple.omp')) {
    return OMPCoreUtilF.resetCartWish(user_counts);
  }

  if (
    !$qs('html.creator') ||
    !!$qs('body.mshop') ||
    !!$qs('html.creator .crew-mall') ||
    !!$qs('html.creator.creator_store') ||
    !!$qs('.don_frame.product-creation-frame') ||
    !!$qs('.don_frame[frame_name="mp.maker.fake.mobile"]') ||
    !!$qs('.don_page[page_name="maker.marpple_shop_page"]')
  ) {
    return go(user_counts || axios.get(`/${ENV.lang}/@api/user_counts`), function ({ data: counts }) {
      if (MShopUtilF.isApp()) return MShopUtilF.postMessage({ cart: { count: counts.cart } });

      each((count_el) => {
        if (counts.cart > 0) $addClass('drawer__cart-count-box--show', count_el);
        else $removeClass('drawer__cart-count-box--show', count_el);
        if (counts.cart > 9) $addClass('drawer__cart-count-box--wide', count_el);
        else $removeClass('drawer__cart-count-box--wide', count_el);
        $setText(counts.cart, $find('.drawer__cart-count', count_el));
      }, $qsa('.drawer__cart-count-box'));

      const cart_badge_el = $qs('.mshop-core-icon-shopping-bag > .mshop-core-icon__badge');
      if (cart_badge_el) {
        $setAttr({ 'data-badge_count': counts.cart }, cart_badge_el);
      }

      const cart_badge_count_el = $qs(
        '.mshop-core-icon-shopping-bag > .mshop-core-icon__badge > .mshop-core-icon__badge__count',
      );
      if (cart_badge_count_el) {
        $setText(counts.cart, cart_badge_count_el);
        $setAttr({ 'data-badge_count': counts.cart }, cart_badge_count_el);
      }

      const parent_els = $qsa('.right_gnb, .right_menu');
      each(
        (type) =>
          go(
            parent_els,
            each((parent_el) => {
              const el$ = $find(`.${type}`, parent_el);
              if (!el$) return;
              $setAttr({ 'data-count': counts[type] }, el$);
              const count_sign = $find('.count_sign', el$);
              if (count_sign) $setText(counts[type], count_sign);
            }),
          ),
        ['cart', 'wish'],
      );
      box.set('user_counts', counts);
      $setAttr({ my_image_count: counts.my_image }, $qs('html'));
      each(
        $setText(counts.my_image),
        $qsa('#maker .circle_icon.my_lib .count, .image_upload_frame .btn_my_lib .count'),
      );

      return counts;
    });
  }
}

export const setUpCSs = curry2((product_color, total_quantity, up_c_s = {}) => {
  const is_seller = box.sel('is_seller');
  const prices = calcDiscountedPrice(
    'kr',
    product_color,
    total_quantity,
    up_c_s.base_product_size_id,
    up_c_s.product_option_id,
    is_seller,
  );

  const price_ens = calcDiscountedPrice(
    'en',
    product_color,
    total_quantity,
    up_c_s.base_product_size_id,
    up_c_s.product_option_id,
    is_seller,
  );

  const price_jps = calcDiscountedPrice(
    'jp',
    product_color,
    total_quantity,
    up_c_s.base_product_size_id,
    up_c_s.product_option_id,
    is_seller,
  );

  const sizes_price = _p.find(product_color.price_info.sizes_prices, function (sizes_price) {
    return sizes_price.id == up_c_s.base_product_size_id;
  });

  up_c_s.price = prices.original_one;
  up_c_s.price_en = price_ens.original_one;
  up_c_s.price_jp = price_jps.original_one;

  up_c_s.discounted_price = prices.discounted_one;
  up_c_s.discounted_price_en = price_ens.discounted_one;
  up_c_s.discounted_price_jp = price_jps.discounted_one;

  up_c_s.print_price = sizes_price.print_price;
  up_c_s.print_price_en = sizes_price.print_price_en;
  up_c_s.print_price_jp = sizes_price.print_price_jp;

  up_c_s.bp_price = sizes_price.product_price;
  up_c_s.bp_price_en = sizes_price.product_price_en;
  up_c_s.bp_price_jp = sizes_price.product_price_jp;

  up_c_s.profit = is_seller ? 0 : product_color.profit || 0;
  up_c_s.profit_en = is_seller ? 0 : product_color.profit_en || 0;
  up_c_s.profit_jp = is_seller ? 0 : product_color.profit_jp || 0;

  up_c_s.oem_price = sizes_price.oem_price || 0;

  return up_c_s;
});

export const setUpCSQtyNPrices = function (up_c_s, quantity, product_color, other_up_cs_quantity) {
  other_up_cs_quantity = parseInt(other_up_cs_quantity || 0);
  const total_quantity = quantity + (other_up_cs_quantity || 0);
  up_c_s.quantity = quantity;

  return setUpCSs(product_color, total_quantity, up_c_s);
};

export const setUpCSsPrices = function (up_c_ss, product_color) {
  const total_quantity = sumBy((up_c_s) => up_c_s.quantity, up_c_ss) || 0;
  return each(setUpCSs(product_color, total_quantity), up_c_ss);
};
