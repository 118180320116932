import dataStr from 'fxdom/es/dataStr.js';
import { filter, find, go, html, map, sel, strMap } from 'fxjs/es';
import { zipWithIndex } from 'fxjs/es/Lazy';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { OMPCartTmplS } from '../../../../Cart/S/Tmpl/module/OMPCartTmplS.js';
import { OMPCoreUtilS } from '../../../../Core/Util/S/Function/module/OMPCoreUtilS.js';

const wishUserProductColor = (up_c, is_last) => {
  const thumbnail = {
    product_face: sel('_.product_color.product_faces2.value.0', up_c),
    thumbnail_url: go(sel('_.product_color.thumbnails.value', up_c), find(sel('is_thumb')), sel('url')),
  };
  const color = up_c._.product_color._.base_product_color['name' + G._en];
  const name = `${up_c._.product_color._.base_product['name' + G._en]} `;
  const print_face =
    go(
      up_c,
      sel('_.product_color.product_faces2.value'),
      filter(sel('designs.length')),
      map((pf) => pf['face_name' + G._en] || pf.face_name_en),
      (r) => r.join(' / '),
    ) || TT('cart::cart_10');

  const select_option_name = up_c._.product_color._.selected_option_group['title' + G._en];
  const sizes = map(
    (up_c_s) => ({
      _is_not_stock: up_c_s._is_not_stock,
      size: select_option_name || up_c_s['_name' + G._en],
      quantity: UtilS.commify(up_c_s.quantity),
    }),
    filter((up_c_s) => up_c_s.quantity, up_c._.up_c_ss),
  );

  return html`
    <div
      class="omp-cart__product omp-my-wish__buy-later__product ${is_last ? 'is_last' : ''}"
      ${OMPCoreUtilS.createDataSet(up_c?.table_data)}
    >
      <div class="omp-cart__product-id">${OMPCartTmplS.cartProductNumberColumnTmpl(up_c)}</div>
      <div class="omp-cart__product-info-wrapper">
        <div
          class="omp-cart__product-thumbnail"
          data-fx-json="${!thumbnail.thumbnail_url && thumbnail.product_face
            ? dataStr(thumbnail.product_face)
            : ''}"
        >
          <div>
            ${thumbnail.thumbnail_url
              ? html` <img src="${thumbnail.thumbnail_url}" alt="" /> `
              : html` <div class="omp-cart__product-thumbnail-canvas-wrapper">
                  <canvas></canvas>
                  <div class="don_loader_wrap2">
                    <div class="don_loader_img2"></div>
                  </div>
                </div>`}
          </div>
        </div>
        <div class="omp-cart__product-info">
          <div class="omp-cart__product-name">${name}</div>
          <div class="omp-cart__product-print">
            <span class="key">${TT('my_page::order::detail::detail_12_01')}</span>
            <span>${print_face}</span>
          </div>
          ${color
            ? html`
                <div class="omp-cart__product-print">
                  <span class="key">${TT('my_page::order::detail::detail_08')}</span>
                  <span>${color}</span>
                </div>
              `
            : ''}
          ${sizes.length
            ? html`<div class="omp-cart__product-size">
                <span class="key">${TT('my_page::order::detail::detail_06')}</span>
                <div class="omp-cart__product-size-set-wrapper">
                  ${strMap(
                    ({ size, quantity, _is_not_stock }) => html`
                      <div class="omp-cart__product-size-set" data-is_not_stock="${_is_not_stock}">
                        <span>${size}</span>
                        <span>${TT('common::quantity', { quantity })}</span>
                        <input name="quantity" type="hidden" value="${quantity}" />
                      </div>
                    `,
                    sizes,
                  )}
                </div>
              </div>`
            : ''}
        </div>
      </div>
      <div class="omp-cart__product-price">${OMPCartTmplS.cartProductPriceColumnTmpl(up_c)}</div>
      <div class="omp-cart__product-edit-wrapper">
        <div class="omp-cart__product-edit-set">
          <button class="omp-my-wish__add-to-cart">${TT('my_page::wish::wish_11')}</button>
        </div>
        <button type="button" class="omp-my-wish__product-delete omp-cart__product-delete">
          ${TT('cart::cart_22')}
        </button>
      </div>
    </div>
  `;
};

export const wishUserProduct = (up_cs) => {
  return html`
    <div class="omp-cart__product-container">
      ${strMap(
        ([index, up_c]) => wishUserProductColor(up_c, index + 1 === up_cs.length),
        zipWithIndex(up_cs),
      )}
    </div>
  `;
};
