import { each, entries, go, html, isString, range, strMap } from 'fxjs/es';
import UAParser from 'ua-parser-js';

export const isMobile = (locals) => {
  if (locals?.ua) return locals.ua.is_mobile && !locals.ua.is_tablet;
  const parser = new UAParser();
  return ['mobile'].includes(parser.getDevice().type);
};
export const isTablet = (locals) => {
  if (locals?.ua) return locals.ua.is_mobile && !locals.ua.is_tablet;
  const parser = new UAParser();
  return ['tablet'].includes(parser.getDevice().type);
};
export const trim = (str) => (isString(str) ? str.trim() : str);
export const toLower = (str) => (isString(str) ? str.toLowerCase() : str);
export const isValidEmail = (val) => {
  if (!val) return false;

  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(val).toLowerCase());
};

export const isSpecialStr = (val) => {
  const reg = /[~!@#$%^&*()'"_+|<>?:{}]/;
  return reg.test(val);
};

export const checkPhoneNumberSms = (number) => {
  // var chk_number_ex = /((00\d{1,3}-?\d{1,3}-\d{1,4})|(0\d{1,2}))-\d{3,4}-\d{4}/; 국제?
  const chk_number = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;
  return chk_number.test(number);
};
export const BLACK_ROUTE_LIST = ['.php', '.gitignore'];
export const createRandomCode = (str_len) => {
  const string_length = str_len || 29;
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
  let randomString = '';
  for (let i = 0; i < string_length; i++) {
    const num = Math.floor(Math.random() * chars.length);
    randomString += chars.substring(num, num + 1);
  }
  return (randomString + 'a').replace(new RegExp(BLACK_ROUTE_LIST.join('|'), 'gi'), (str) =>
    range(str.length).join(''),
  );
};

export const createDataSet = (data) =>
  data ? strMap(([key, value]) => html` data-${key}="${value}" `, entries(data)) : '';
export const createStyleSet = (style) =>
  style ? strMap(([key, value]) => html` data-style_${key}="${value}" `, entries(style)) : '';

export const isNumeric = (data) => {
  return !isNaN(Number(data));
};

export const getOnlyNumber = (number) => {
  return number.replace(/[^0-9]/g, '');
};

export const customParamsSerializer = (params) => {
  // eslint-disable-next-line no-undef
  const search_params = new URLSearchParams();
  go(
    entries(params),
    each(([key, value]) => {
      if (Array.isArray(value)) {
        return each((v) => search_params.append(key, v), /** @type Array */ value);
      }
      return search_params.append(key, value);
    }),
  );
  return search_params.toString();
};

/**
 * @param {string?} str
 * @param {T|number?} [fallback_value = 0]
 * @return {T|number}
 */
export const safeParseInt = (str, fallback_value = 0) => {
  try {
    const parsed = parseInt(str);
    return isNaN(parsed) ? fallback_value : parsed;
  } catch (e) {
    return fallback_value;
  }
};

export const col = (name = '', _en = G._en) => `${name}${_en}`;

export const BR = (str) => {
  return str
    .replace(/\n\r/g, '<br data-pc-only />')
    .replace(/\n/g, '<br />')
    .replace(/\r/g, '<br data-mobile-only />');
};

export const shuffle = (array) => {
  const result = [...array];

  for (let index = result.length - 1; index > 0; index--) {
    const randomPosition = Math.floor(Math.random() * (index + 1));

    const temporary = result[index];
    result[index] = result[randomPosition];
    result[randomPosition] = temporary;
  }

  return result;
};

export const createSmsCode = () => {
  return go(
    '012345567890123455678901234556789',
    shuffle,
    (arr) => arr.join(''),
    (str) => str.substring(0, 6),
  );
};

/**
 * @param {number|string} num
 * @return {string}
 */
export const setCommaEvery3Digits = (num) => {
  if (num instanceof String && !Number.isInteger(parseInt(num))) {
    throw new Error('num is not valid.');
  }
  if (!Number.isInteger(num)) {
    throw new Error('num is not valid.');
  }
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

/* 한글 단어 끝에 받침이 있는지 */
export const isEndWithConsonant = function (korStr) {
  const finalChrCode = korStr.charCodeAt(korStr.length - 1);
  const finalConsonantCode = (finalChrCode - 44032) % 28;
  return finalConsonantCode !== 0;
};

export function getSnsUrl(urls) {
  if (findYoutube(urls)) return findYoutube(urls);
  if (findTwitter(urls)) return findTwitter(urls);
  if (findInstagram(urls)) return findInstagram(urls);
  if (findTiktok(urls)) return findTiktok(urls);
  if (findNaverBlog(urls)) return findNaverBlog(urls);
  if (findFacebook(urls)) return findFacebook(urls);

  return null;
}

function findYoutube(url) {
  if (isString(url) && (url.indexOf('youtube') !== -1 || url.indexOf('youtu.be') !== -1)) {
    return { type: 'youtube', url };
  }
}

function findInstagram(url) {
  if (isString(url) && url.indexOf('instagram') !== -1) {
    return { type: 'instagram', url };
  }
}

function findTwitter(url) {
  if (isString(url) && url.indexOf('twitter') !== -1) {
    return { type: 'twitter', url };
  }
}

function findNaverBlog(url) {
  if (isString(url) && url.indexOf('blog.naver') !== -1) {
    return { type: 'blog.naver', url };
  }
}

function findFacebook(url) {
  if (isString(url) && url.indexOf('facebook') !== -1) {
    return { type: 'facebook', url };
  }
}

function findTiktok(url) {
  if (isString(url) && url.indexOf('tiktok') !== -1) {
    return { type: 'tiktok', url };
  }
}
